@import url('https://fonts.googleapis.com/css2?family=Shrikhand');
@import url('https://fonts.googleapis.com/css2?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.App {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.row-top {
  background-color: #FFB549;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
}

.row-top-content {
  width: 80%;
}

.shrikhand {
  font-family: 'Shrikhand', cursive;
  font-size: 40px;
  /* font-size: calc(12px + 1vw) */
  color: #FFF;
  margin: 0;
  padding: 0;
}

.nunito {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #000;
  margin: 0;
  padding: 0;
}

.nunito-sans {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin: 0;
  padding: 0;
}

.black-text {
  color: #000;
}

.error-text {
  color: red;
}

.logo {
  width: 100%;
  margin: 2% 0 0 0%;
}

.row {
  display: flex;
  flex-direction: row;
}

.column-tagline {
  margin: 3% 0 0 5%;
  flex: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.column-tagline .column_row2 {
  margin-left: -0.5%;
}

.column-tagline p:last-of-type {
  margin-top: 2%;
}

.narchie-intro {
  font-size: 21px;
  width: 80%;
}

.store-buttons {
  display: flex;
  max-width: 50%;
  flex: 0.2;
  padding-top: 2%;
}

.ios-download-btn {
  height: 54px;
}

.droid-download-btn {
  height: 61px;
}

.seller-interest {
  padding: 1% 0 1% 0;
}

.seller-interest p {
  margin: 0 0 2% 0;
  font-weight: bold;
}

.seller-interest span {
  font-weight: bold;
}

.column-app-preview {
  display: flex;
  flex: 1;
}

.column-app-preview img {
  margin-top: -10%;
  width: 429px;
  height: 499px;
}

.row-bottom {
  background-color: #FFF;
  width: 100%;
  display: flex;
  justify-content: center;
}

.row-bottom-content {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-bottom p {
  margin: 0;
  padding: 0;
}

.android-title {
  margin: 7% 0 0 0 !important;
}

.android-subtitle {
  width: 45%;
  margin: 1% 0 1% 0 !important;
  text-align: center;
}

#mc_form {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1%;
  width: 40%;
}

#mc_form .mc_status {
  padding: 2%;
  margin: 1%;
}

.form_input {
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.footer {
  background-color: #FFF;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-top-color: #F0F0F0;
  border-top-width: 1px;
  border-top-style: solid;
  padding-bottom: 1%;
}

.footer-column-left {
  flex: 1;
  background-color: white;
}

.footer-column-right {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 1% 4% 0 0;
}

.social-media-icons .insta-icon {
  margin: 0 0 -0.3% 2%;
}

.footer-column-right .address {
  border-top-color: #F0F0F0;
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: 2%;
}

.footer-column-left p {
  margin: 6% 0 0 6%;
}

.footer p {
  font-family: 'Nunito-Sans', sans-serif;
  font-weight: 400;
  font-size: 12px; 
}

/* Main mobile layout */
@media only screen and (max-width: 850px) {

  .row-top-content {
    width: 100%;
  }
  .row-bottom-content {
    width: 100%;
  }

  .column-tagline {
    margin: 0;
    width: 90%;
    align-self: center;
    padding-bottom: 5%;
  }

  .shrikhand {
    font-size: 32px;
  }

  .narchie-intro {
    font-size: 18px;
    padding: 1% 0 3% 0;
    width: 100%;
  }

  .store-buttons {
    align-self: center;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .ios-download-btn {
    height: 50px;
  }
  
  .droid-download-btn {
    height: 57px;
  }

  .logo {
    margin: 2% 0 3% 0;
    text-align: center;
  }  

  .row {
    flex-direction: column;
  }

  .row-top {
    padding: 0;
    margin: 0;
  }

  .column-tagline {
    order: 2;
    text-align: center;
    flex: 1;
  }

  .seller-interest p {
    margin: 0 0 1% 0;
    font-weight: bold;
  }

  .column-app-preview {
    order: 1;
    justify-content: center;
    flex: 1;
  }

  .column-app-preview img {
    width: 75%;
    height: auto;
    margin-top: -1%;
  }

  .android-subtitle {
    width: 85%;
    margin: 1% 0 2% 0 !important;
    text-align: center;
  }

  #mc_form {
    width: 85%;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer-column-left {
    text-align: center;
  }

  .footer-column-left p {
    margin: 3% 0 0 0;
  }

  .footer-column-right {
    flex: 1;
    align-items: center;
    padding: 2% 0 0 0;
  }

  .social-media-icons {
    display: flex;
    flex-direction: row;
  }

  .social-media-icons .li-icon {
    margin: 10% 0 0 0;
  }

  .social-media-icons .insta-icon {
    margin: 0 0 0 15%;
  }

}

/* phones */
@media only screen and (max-width: 767px) {
  .android-title {
    width: 85%;
    font-size: 24px;
    text-align: center;
  }
}

/* tablets - portrait */
/* @media only screen and (max-width: 1024px) {
} */

/* iPad pro 10.5 Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    /* .App-footer {
      background-color: #FFB549;
    } */
}

/* iPad pro 12.9 Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    /* .App-footer {
      background-color: #FFB549;
    } */
}

/* unvisited link */
a:link {
  color: #2F80ED;
}

/* visited link */
a:visited {
  color: #2F80ED;
}

/* mouse over link */
a:hover {
  color: #2F80ED;
}

/* selected link */
a:active {
  color: #2F80ED;
}